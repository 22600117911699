import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import i18n from 'i18next';

function ChooseLang() {
  const { Option } = Select;

  function handleClick(value) {
    i18n.changeLanguage(value);
  }
  return (
    <div>
      <h4>Choose Language</h4>
      <Select
        dropdownClassName="choose-lang-select-menu"
        className="choose-lang-select"
        defaultValue="en"
        onChange={handleClick}
        dropdownStyle={{
          height: '72px'
        }}
        suffixIcon={<CaretDownOutlined className="form-dropdown-arrow" />}
      >
        <Option value="en">English</Option>
        <Option value="arm">Armenian</Option>
      </Select>
    </div>
  );
}

export default ChooseLang;
