import { v4 as uuidv4 } from 'uuid';

export const PatientInfoQuestions = {
  location: {
    //TODO: Add location concept name for each
    title: 'Location',
    questionConceptName: 'Avetis Field Operations Location',
    answers: [
      {
        id: uuidv4(),
        description: 'Yerevan',
        answerValue: 'Yerevan'
      },
      {
        id: uuidv4(),
        description: 'Aragatsotn',
        answerValue: 'Aragatsotn'
      },
      {
        id: uuidv4(),
        description: 'Ararat',
        answerValue: 'Ararat'
      },
      {
        id: uuidv4(),
        description: 'Armavir',
        answerValue: 'Armavir'
      },
      {
        id: uuidv4(),
        description: 'Gegharkunik',
        answerValue: 'Gegharkunik'
      },
      {
        id: uuidv4(),
        description: 'Kotayk',
        answerValue: 'Kotayk'
      },
      {
        id: uuidv4(),
        description: 'Lori',
        answerValue: 'Lori'
      },
      {
        id: uuidv4(),
        description: 'Shirak',
        answerValue: 'Shirak'
      },
      {
        id: uuidv4(),
        description: 'Syunik',
        answerValue: 'Syunik'
      },
      {
        id: uuidv4(),
        description: 'Tavush',
        answerValue: 'Tavush'
      },
      {
        id: uuidv4(),
        description: 'Vayots Dzor',
        answerValue: 'Vayots Dzor'
      }
    ]
  },
  primaryPatientAge: {
    title: 'Primary Patient Age',
    questionConceptName: 'Avetis Field Operations Age',
    answers: [
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 0-5',
        description: '0 - 5 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 6-10',
        description: '6 - 10 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 11-15',
        description: '11 - 15 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 16-20',
        description: '16 - 20 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 21-30',
        description: '21 - 30 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 31-40',
        description: '31 - 40 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 41-50',
        description: '41 - 50 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 51-60',
        description: '51 - 60 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 61-70',
        description: '61 - 70 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 71-80',
        description: '71 - 80 years old'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Age 81+',
        description: '81+ years old'
      }
    ]
  },
  primaryPatientGender: {
    title: 'Primary Patient Gender',
    questionConceptName: 'Avetis Field Operations Gender',
    answers: [
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Gender Male',
        description: 'Male'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Gender Female',
        description: 'Female'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Gender Other',
        description: 'Other'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Gender Not Specified',
        description: 'Not Specified'
      }
    ]
  },
  presentAtSession: {
    title: 'Also Present at Session',
    questionConceptName: 'Avetis Field Operations Patient Status',
    answers: [
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Patient Status Child',
        description: 'Child'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Patient Status Adolescent',
        description: 'Adolescent'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Patient Status Adult',
        description: 'Adult'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Patient Status Family',
        description: 'Family'
      },
      {
        id: uuidv4(),
        answerConceptName: 'Avetis Field Operations Patient Status Group',
        description: 'Group'
      }
    ]
  },
  setting: {
    title: 'Setting',
    questionConceptName: 'Avetis Field Operations Setting'
  },
  date: {
    title: 'Date',
    questionConceptName: 'Avetis Field Operations Date'
  }
};

export const HesperQuestions = [
  {
    id: uuidv4(),
    questionTitle: 'Drinking water',
    question:
      'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?',
    questionConceptName: 'hesperDrinkingWater'
  },
  {
    id: uuidv4(),
    questionTitle: 'Food',
    question:
      'Do you have a serious problem with food? For example, because you do not have enough food, or good enough food, or because you are not able to cook food.',
    questionConceptName: 'hesperFood'
  },
  {
    id: uuidv4(),
    questionTitle: 'Place to live in',
    question:
      'Do you have a serious problem because you do not have a suitable place to live in?',
    questionConceptName: 'hesperPlaceToLiveIn'
  },
  {
    id: uuidv4(),
    questionTitle: 'Toilets',
    question:
      'Do you have a serious problem because you do not have easy and safe access to a clean toilet?',
    questionConceptName: 'hesperToilets'
  },
  {
    id: uuidv4(),
    questionTitle: 'Keeping clean',
    question:
      'For men: Do you have a serious problem because in your situation it is difficult to keep clean? For example, because you do not have enough soap, water or a suitable place to wash.\n For women: Do you have a serious problem because in your situation it is difficult to keep clean? For example, because you do not have enough soap, sanitary materials, water or a suitable place to wash.',
    questionConceptName: 'hesperKeepingClean'
  },
  {
    id: uuidv4(),
    questionTitle: 'Clothes, shoes, bedding or blankets',
    question:
      'Do you have a serious problem because you do not have enough, or good enough, clothes, shoes, bedding or blankets?',
    questionConceptName: 'hesperClothes'
  },
  {
    id: uuidv4(),
    questionTitle: 'Income or livelihood',
    question:
      'Do you have a serious problem because you do not have enough income, money or resources to live?',
    questionConceptName: 'hesperIncome'
  },
  {
    id: uuidv4(),
    questionTitle: 'Physical health',
    question:
      'Do you have a serious problem with your physical health? For example, because you have a physical illness, injury or disability.',
    questionConceptName: 'hesperHealth'
  },
  {
    id: uuidv4(),
    questionTitle: 'Health care',
    question:
      'For men: Do you have a serious problem because you are not able to get adequate health care for yourself? For example, treatment or medicines.\n For women: Do you have a serious problem because you are not able to get adequate health care for yourself? For example, treatment or medicines, or health care during pregnancy or childbirth.',
    questionConceptName: 'hesperHealthCare'
  },
  {
    id: uuidv4(),
    questionTitle: 'Distress',
    question:
      'Do you have a serious problem because you feel very distressed? For example, very upset, sad, worried, scared, or angry.',
    questionConceptName: 'hesperDistress'
  },
  {
    id: uuidv4(),
    questionTitle: 'Safety',
    question:
      'Do you have a serious problem because you or your family are not safe or protected where you live now? For example, because of conflict, violence or crime in your community, city or village.',
    questionConceptName: 'hesperSafety'
  },
  {
    id: uuidv4(),
    questionTitle: 'Education for your children',
    question:
      'Do you have a serious problem because your children are not in school or are not getting a good enough education?',
    questionConceptName: 'hesperChildrensEducation'
  },
  {
    id: uuidv4(),
    questionTitle: 'Care for family members',
    question:
      'Do you have a serious problem because in your situation it is difficult to care for family members who live with you? For example, young children in your family, or family members who are elderly, physically or mentally ill, or disabled.',
    questionConceptName: 'hesperCareFamily'
  },
  {
    id: uuidv4(),
    questionTitle: 'Support from others',
    question:
      'Do you have a serious problem because you are not getting enough support from people in your community? For example, emotional support or practical help.',
    questionConceptName: 'hesperSupport'
  },
  {
    id: uuidv4(),
    questionTitle: 'Separation from family members',
    question:
      'Do you have a serious problem because you are separated from family members?',
    questionConceptName: 'hesperSeparationFromFamily'
  },
  {
    id: uuidv4(),
    questionTitle: 'Being displaced from home',
    question:
      'Do you have a serious problem because you have been displaced from your home country, city or village?',
    questionConceptName: 'hesperDisplacedFromHome'
  },
  {
    id: uuidv4(),
    questionTitle: 'Information',
    question:
      'For displaced people: Do you have a serious problem because you do not have enough information? For example, because you do not have enough information about the aid that is available or because you do not have enough information about what is happening in your home country or hometown.\n For non-displaced people: Do you have a serious problem because you do not have enough information? For example, because you do not have enough information about the aid that is available.',
    questionConceptName: 'hesperInformation'
  },
  {
    id: uuidv4(),
    questionTitle: 'The way aid is provided',
    question:
      'Do you have a serious problem because of inadequate aid? For example, because you do not have fair access to the aid that is available, or because aid agencies are working on their own without involvement from people in your community.',
    questionConceptName: 'hesperHowAidProvided'
  },
  {
    id: uuidv4(),
    questionTitle: 'Respect',
    question:
      'Do you have a serious problem because you do not feel respected or you feel humiliated? For example, because of the situation you are living in, or because of the way people treat you.',
    questionConceptName: 'hesperRespect'
  },
  {
    id: uuidv4(),
    questionTitle: 'Moving between places',
    question:
      'Do you have a serious problem because you are not able to move between places? For example, going to another village or town.',
    questionConceptName: 'hesperMovingBetweenPlaces'
  },
  {
    id: uuidv4(),
    questionTitle: 'Law and justice in your community',
    question:
      'Is there a serious problem in your community because of an inadequate system for law and justice, or because people do not know enough about their legal rights?',
    questionConceptName: 'hesperLawJustice'
  },
  {
    id: uuidv4(),
    questionTitle:
      'Safety or protection from violence for women in your community',
    question:
      'Is there a serious problem for women in your community because of physical or sexual violence towards them, either in the community or in their homes?',
    questionConceptName: 'hesperSafetyFromViolenceWomen'
  },
  {
    id: uuidv4(),
    questionTitle: 'Alcohol or drug use in your community',
    question:
      'Is there a serious problem in your community because people drink a lot of alcohol, or use harmful drugs?',
    questionConceptName: 'hesperAlcoholDrugs'
  },
  {
    id: uuidv4(),
    questionTitle: 'Mental illness in your community',
    question:
      'Is there a serious problem in your community because people have a mental illness?',
    questionConceptName: 'hesperMentalIlness'
  },
  {
    id: uuidv4(),
    questionTitle: 'Care for people in your community who are on their own',
    question:
      'Is there a serious problem in your community because there is not enough care for people who are on their own? For example, care for unaccompanied children, widows or elderly people, or unaccompanied people who have a physical or mental illness, or disability.',
    questionConceptName: 'hesperCareForPeopleOnOwn'
  },
  {
    id: uuidv4(),
    questionTitle: 'COVID-19',
    question:
      'Is there a serious problem accessing supplies like face masks, hand sanitizer, soap, and water to be able to prevent the spread of COVID in your community?',
    questionConceptName: 'hesperCovid19Supplies'
  },
  {
    id: uuidv4(),
    questionTitle: 'COVID-19 social distance',
    question:
      'Is there a serious problem not being able to maintain social distance from people outside of your immediate family?',
    questionConceptName: 'hesperCovid19SocialDistance'
  },
  {
    id: uuidv4(),
    questionTitle: 'Technology',
    question:
      'Do you have access to a cell phone, the power necessary to charge the device and reliable wireless access?',
    questionConceptName: 'hesperAccessToTech'
  },
  {
    id: uuidv4(),
    questionTitle: 'Technology cost',
    question: 'Are you able to afford the cost of a cell phone?',
    questionConceptName: 'hesperAffordTech'
  }
];

export const PfaQuestions = {
  behaviour: {
    title: 'Behaviour',
    questionConceptName: 'Behavior',
    answers: [
      {
        id: uuidv4(),
        description: 'Extreme disorientation',
        answerConceptName: 'ExtremeDisorientation'
      },
      {
        id: uuidv4(),
        description: 'Excessive drug, alcohol, or prescription drug use',
        answerConceptName: 'ExcessiveDrugAlcoholPrescriptionDrugUse'
      },
      {
        id: uuidv4(),
        description: 'Isolation/ withdrawal',
        answerConceptName: 'IsolationWithdrawal'
      },
      {
        id: uuidv4(),
        description: 'High risk behavior',
        answerConceptName: 'HighRiskBehavior'
      },
      {
        id: uuidv4(),
        description: 'Regressive behavior',
        answerConceptName: 'RegressiveBehavior'
      },
      {
        id: uuidv4(),
        description: 'Separation anxiety',
        answerConceptName: 'SeparationAnxiety'
      },
      {
        id: uuidv4(),
        description: 'Violent behavior',
        answerConceptName: 'ViolentBehavior'
      },
      {
        id: uuidv4(),
        description: 'Maladaptive coping',
        answerConceptName: 'MaladaptiveCoping'
      },
      {
        id: uuidv4(),
        description: 'None of the above',
        answerConceptName: 'NoneOfTheAbove'
      },
      {
        id: uuidv4(),
        description: 'Other (please specify)',
        answerConceptName: 'BehaviorOther',
        textarea: true
      }
    ]
  },
  emotional: {
    title: 'Emotional',
    questionConceptName: 'Emotional',
    answers: [
      {
        id: uuidv4(),
        description: 'Acute stress reactions',
        answerConceptName: 'AcuteStressReactions'
      },
      {
        id: uuidv4(),
        description: 'Acute grief reactions',
        answerConceptName: 'AcuteGriefReactions'
      },
      {
        id: uuidv4(),
        description: 'Sadness, tearfulness',
        answerConceptName: 'SadnessTearfulness'
      },
      {
        id: uuidv4(),
        description: 'Irritability, anger',
        answerConceptName: 'IrritabilityAnger'
      },
      {
        id: uuidv4(),
        description: 'Feeling anxious, fearful',
        answerConceptName: 'FeelingAnxiousFearful'
      },
      {
        id: uuidv4(),
        description: 'Despair, hopelessness',
        answerConceptName: 'DespairHopelessness'
      },
      {
        id: uuidv4(),
        description: 'Feelings of guilt or shame',
        answerConceptName: 'FeelingsGuiltShame'
      },
      {
        id: uuidv4(),
        description: 'Feeling emotionally numb, disconnected',
        answerConceptName: 'FeelingEmotionallyNumbDisconnected'
      },
      {
        id: uuidv4(),
        description: 'None of the above',
        answerConceptName: 'NoneOfTheAbove'
      },
      {
        id: uuidv4(),
        description: 'Other (please specify)',
        answerConceptName: 'EmotionalOther',
        textarea: true
      }
    ]
  },
  difficultiesSurvivor: {
    title: 'Difficulties Survivor',
    questionConceptName: 'DifficultiesExperiencing',
    answers: [
      {
        id: uuidv4(),
        description:
          'Past or preexisting trauma/psychological problems/substance abuse problems',
        answerConceptName:
          'PastOrPreexistingTraumaPsychologicalProblemsSubstanceAbuse'
      },
      {
        id: uuidv4(),
        description: 'Injured as a result of the war',
        answerConceptName: 'InjuredResultOfWar'
      },
      {
        id: uuidv4(),
        description: 'At risk of losing life during the war',
        answerConceptName: 'RiskLosingLifeDuringWar'
      },
      {
        id: uuidv4(),
        description: 'Loved one(s) missing or dead',
        answerConceptName: 'LovedOnesMissingDead'
      },
      {
        id: uuidv4(),
        description: 'Ill/injured as a result of COVID-19 pandemic',
        answerConceptName: 'IllInjuredResultCOVID19'
      },
      {
        id: uuidv4(),
        description:
          'Ill/injured/lost loved one as a result of COVID-19 pandemic',
        answerConceptName: 'IllInjuredLostLovedOneFromCOVID19'
      },
      {
        id: uuidv4(),
        description: 'Financial concerns',
        answerConceptName: 'FinancialConcerns'
      },
      {
        id: uuidv4(),
        description: 'Displaced from home',
        answerConceptName: 'DisplacedFromHome'
      },
      {
        id: uuidv4(),
        description: 'Living arrangements',
        answerConceptName: 'LivingArrangements'
      },
      {
        id: uuidv4(),
        description: 'Lost job or school',
        answerConceptName: 'LostJobSchool'
      },
      {
        id: uuidv4(),
        description: 'Assisted with rescue/recovery',
        answerConceptName: 'AssistedWithRescueRecovery'
      },
      {
        id: uuidv4(),
        description: 'Has physical/emotional disability',
        answerConceptName: 'PhysicalEmotionalDisability'
      },
      {
        id: uuidv4(),
        description: 'Medication stabilization',
        answerConceptName: 'MedicationStabilization'
      },
      {
        id: uuidv4(),
        description: 'Concerns about child/adolescent',
        answerConceptName: 'ConcernsAboutChildAdolescent'
      },
      {
        id: uuidv4(),
        description: 'Spiritual concerns',
        answerConceptName: 'SpiritualConcerns'
      },
      {
        id: uuidv4(),
        description: 'None of the above',
        answerConceptName: 'NoneOfTheAbove'
      },
      {
        id: uuidv4(),
        description: 'Other (please specify)',
        answerConceptName: 'DifficultiesOther',
        textarea: true
      }
    ]
  },
  referral: {
    title: 'Referral',
    questionConceptName: 'Referral',
    answers: [
      {
        id: uuidv4(),
        description: 'Substance abuse treatment',
        answerConceptName: 'SubstanceAbuseTreatment'
      },
      {
        id: uuidv4(),
        description: 'Other disaster agencies',
        answerConceptName: 'OtherDisasterAgencies'
      },
      {
        id: uuidv4(),
        description: 'Other community services',
        answerConceptName: 'OtherCommunityServices'
      },
      {
        id: uuidv4(),
        description: 'Professional mental health services',
        answerConceptName: 'ProfessionalMentalHealthServices'
      },
      {
        id: uuidv4(),
        description: 'Clergy',
        answerConceptName: 'Clergy'
      },
      {
        id: uuidv4(),
        description: 'Medical treatment',
        answerConceptName: 'MedicalTreatment'
      },
      {
        id: uuidv4(),
        description: 'Did not make referral',
        answerConceptName: 'DidNotMakeReferral'
      },
      {
        id: uuidv4(),
        description: 'Within project (please specify)',
        answerConceptName: 'referralWithinProject',
        textarea: true
      },
      {
        id: uuidv4(),
        description: 'Other (please specify)',
        answerConceptName: 'otherInformationForReferral.',
        textarea: true
      }
    ]
  },
  referralAcceptance: {
    title: 'Referral Accepted By Individual',
    questionConceptName: 'ReferralAcceptedByIndividual',
    answers: [
      {
        id: uuidv4(),
        description: 'Yes',
        answerConceptName: 'Yes'
      },
      {
        id: uuidv4(),
        description: 'No',
        answerConceptName: 'No'
      },
    ]
  },
  referralOtherInfo: {
    title: 'otherInformationForReferral.',
    questionConceptName: 'otherInformationForReferral.'
  }
};
