import React, { useState, useEffect } from 'react';
import {
  mapAnswersToFormSubmission,
  removeAnswer,
  addAnswer
} from '../../Shared/Shared';
import { PatientInfoQuestions } from '../../Shared/FormsQuestions';
import { savePatientInfo } from '../../Store/actions/form';
import { connect } from 'react-redux';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import PresentAtSession from '../../Components/PatientInfo/PresentAtSession';
import FormDropdown from '../../Components/PatientInfo/FormDropdown';
import DatePick from '../../Components/PatientInfo/DatePicker';
import Setting from '../../Components/PatientInfo/Setting';
import './PatientInfo.scss';

const requiredQuestionsConceptNames = [
  PatientInfoQuestions.date.questionConceptName,
  PatientInfoQuestions.primaryPatientAge.questionConceptName,
  PatientInfoQuestions.primaryPatientGender.questionConceptName,
  PatientInfoQuestions.location.questionConceptName
];

function PatientInfo(props) {
  const [answers, setAnswers] = useState({});
  const [ableToSave, setAbleToSave] = useState(false);

  const addToAnswers = answer => addAnswer(answer, answers, setAnswers);
  const removeFromAnswers = answer => removeAnswer(answer, answers, setAnswers);

  const savePatientInfo = () => {
    props.savePatientInfo(mapAnswersToFormSubmission(answers));
  };

  useEffect(() => {
    const answeredQuestionsConceptNames = Object.keys(answers);
    const abilityToSave = requiredQuestionsConceptNames.reduce(
      (reduced, conceptName) =>
        reduced && answeredQuestionsConceptNames.includes(conceptName),
      true
    );
    setAbleToSave(abilityToSave);
  }, [answers]);

  return (
    <div className="PatientInfo-form-wrapper">
      <h5 className="PatientInfo-form-title">
        Patient Visit
        <br /> Information
      </h5>
      <DatePick addToAnswers={addToAnswers} />
      <FormDropdown
        {...PatientInfoQuestions.location}
        addToAnswers={addToAnswers}
        removeFromAnswers={removeFromAnswers}
      />
      <FormDropdown
        {...PatientInfoQuestions.primaryPatientAge}
        addToAnswers={addToAnswers}
        removeFromAnswers={removeFromAnswers}
      />
      <FormDropdown
        {...PatientInfoQuestions.primaryPatientGender}
        addToAnswers={addToAnswers}
        removeFromAnswers={removeFromAnswers}
      />
      <PresentAtSession
        questionConceptName={
          PatientInfoQuestions.presentAtSession.questionConceptName
        }
        addToAnswers={addToAnswers}
        removeFromAnswers={removeFromAnswers}
      />
      <Setting addToAnswers={addToAnswers} />
      <div className="save-profile-btn-wrapper">
        {ableToSave ? (
          <Link
            className="save-profile-btn"
            to={{
              pathname: Paths.FormDescription,
              state: { referrer: props.location?.state?.referrer }
            }}
            onClick={savePatientInfo}
          >
            Save Profile
          </Link>
        ) : (
          <button disabled className="disabled-save-profile-btn">
            Save Profile
          </button>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  savePatientInfo: patientInfoAnswers =>
    dispatch(savePatientInfo(patientInfoAnswers))
});

export default connect(null, mapDispatchToProps)(PatientInfo);
