import React, { useState } from 'react';
import HesperQuestionsForm from '../../Components/Hesper/HesperQuestionsForm';
import HesperFormHead from '../../Components/FormHead/FormHead';
import './Hesper.scss';

function Hesper() {
  const [progress, setProgress] = useState(0);
  return (
    <div>
      <HesperFormHead formTitle="HESPER" progress={progress} />
      <HesperQuestionsForm setProgress={setProgress} />
    </div>
  );
}

export default Hesper;
