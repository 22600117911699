import React, { useState } from 'react';
import { useDeepCompareEffect } from '../../Utils/UseDeepCompareEffect';
import { PfaQuestions } from '../../Shared/FormsQuestions';
import { submitForm } from '../../Store/actions/form';
import { connect } from 'react-redux';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import {
  mapAnswersToFormSubmission,
  removeAnswer,
  addAnswer,
  FormsIds
} from '../../Shared/Shared';
import PfaQuestionsWrapper from '../../Components/Pfa/PfaQuestionsWrapper';
import ReferralAcceptance from '../../Components/Pfa/ReferralAcceptance';
import ReferralOtherInfo from '../../Components/Pfa/ReferralOtherInfo';
import PfaFormHead from '../../Components/FormHead/FormHead';
import './Pfa.scss';

const Pfa = props => {
  const [currentQuestionIndex, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [ableToSubmit, setAbleToSubmit] = useState(false);

  const addToAnswers = answer => addAnswer(answer, answers, setAnswers);
  const removeFromAnswers = answer => removeAnswer(answer, answers, setAnswers);

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestionIndex + 1);
  };

  const previousQuestion = () => {
    setCurrentQuestion(currentQuestionIndex - 1);
  };

  useDeepCompareEffect(() => {
    const areRequiredAnswered = FormQuestions.reduce(
      (reduced, question) =>
        question.required
          ? reduced && answers[question.questionConceptName]?.length
          : reduced,
      true
    );

    setAbleToSubmit(areRequiredAnswered);
  }, [answers]);

  const FormQuestions = [
    {
      content: (
        <PfaQuestionsWrapper
          {...PfaQuestions.behaviour}
          addToAnswers={addToAnswers}
          removeFromAnswers={removeFromAnswers}
          required
        />
      ),
      questionConceptName: PfaQuestions.behaviour.questionConceptName,
      required: true
    },
    {
      content: (
        <PfaQuestionsWrapper
          {...PfaQuestions.emotional}
          addToAnswers={addToAnswers}
          removeFromAnswers={removeFromAnswers}
          required
        />
      ),
      questionConceptName: PfaQuestions.emotional.questionConceptName,
      required: true
    },
    {
      content: (
        <PfaQuestionsWrapper
          {...PfaQuestions.difficultiesSurvivor}
          addToAnswers={addToAnswers}
          removeFromAnswers={removeFromAnswers}
          required
        />
      ),
      questionConceptName:
        PfaQuestions.difficultiesSurvivor.questionConceptName,
      required: true
    },
    {
      content: (
        <PfaQuestionsWrapper
          {...PfaQuestions.referral}
          addToAnswers={addToAnswers}
          removeFromAnswers={removeFromAnswers}
        />
      ),
      questionConceptName: PfaQuestions.referral.questionConceptName,
      required: false
    },
    {
      content: <ReferralOtherInfo addToAnswers={addToAnswers} />,
      questionConceptName: PfaQuestions.referralOtherInfo.questionConceptName,
      required: false
    },
    {
      content: (
        <PfaQuestionsWrapper
          { ...PfaQuestions.referralAcceptance }
          addToAnswers={addToAnswers}
          removeFromAnswers={removeFromAnswers}
          required
        />
      )
    }
  ];

  const submitForm = () => {
    const formSubmission = { formId: FormsIds.Pfa };
    formSubmission.answers = [
      ...props.patientInfoAnswers,
      ...mapAnswersToFormSubmission(answers)
    ];

    props.submitForm(formSubmission);
  };

  const isQuestionAnswered = () =>
    answers[FormQuestions[currentQuestionIndex].questionConceptName];

  const shouldProceedNext = () =>
    !FormQuestions[currentQuestionIndex].required || isQuestionAnswered();

  return (
    <div className="pfa-form-wrapper">
      <PfaFormHead
        formTitle="PFA Form"
        progress={Math.trunc(
          (Object.keys(answers).length / FormQuestions.length) * 100
        )}
      />

      <div className="steps-content fadeIn_animated">
        {FormQuestions.map(({ content }, index) => (
          <div
            key={index}
            style={index === currentQuestionIndex ? {} : { display: 'none' }}
            className="fadeIn_animated"
          >
            {content}
          </div>
        ))}
      </div>

      <div className="steps-action">
        {currentQuestionIndex > 0 && (
          <button
            className="pfa-form-action"
            onClick={() => previousQuestion()}
          >
            Previous
          </button>
        )}

        {currentQuestionIndex < FormQuestions.length - 1 && (
          <button
            className={`pfa-form-action fadeIn_animated ${
              !isQuestionAnswered() && 'pfa-form-action-white'
            }`}
            onClick={() => nextQuestion()}
            disabled={!shouldProceedNext()}
          >
            Next
          </button>
        )}
      </div>

      {ableToSubmit && currentQuestionIndex === FormQuestions.length - 1 && (
        <div className="submit-btn-wrapper fadeIn_animated">
          <Link to={Paths.submitSuccess}>
            <button className="submit-btn" onClick={submitForm}>
              Submit
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ form }) => ({
  patientInfoAnswers: form.patientInfoAnswers || []
});

const mapDispatchToProps = dispatch => ({
  submitForm: formSubmission => dispatch(submitForm(formSubmission))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pfa);
