import { Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (true) {
          //TODO: Add authorization check before redirecting
          return <Component {...props} />;
        } else {
          return <h4>Unauthorized access!</h4>;
        }
      }}
    />
  );
};

export default PrivateRoute;
