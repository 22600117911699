export const FormsIds = {
  Hesper: 'Hesper',
  Pfa: 'survivorDifficulties'
};

export const YesNoAnswers = {
  yes: { label: 'Yes', value: '1' },
  no: { label: 'No', value: '0' },
  decline: { label: 'Decline to answer', value: '9' },
  notAvailable: { label: 'Not available', value: '9' }
};

export const addAnswer = (answer, answers, setAnswers) => {
  const filteredConceptAnswers =
    answers[answer.questionConceptName]?.filter(({ id }) => id !== answer.id) ||
    [];

  setAnswers({
    ...answers,
    [answer.questionConceptName]: [...filteredConceptAnswers, answer]
  });


  if(answer.answerValue === "") {
    delete answer["answerValue"]
  }

  if (answer.answerValue && answer.answerConceptName && answer.answerValue !== "") {
    setAnswers({
      ...answers,
      [`${answer.answerConceptName}`]:
        [ 
          ...filteredConceptAnswers,
          { ...answer, questionConceptName: `${answer.answerConceptName}` }
        ]
    });
  }
};

export const removeAnswer = (answer, answers, setAnswers) => {
  const {
    [answer.questionConceptName]: conceptAnswers,
    ...filteredAnswers
  } = answers;

  const filteredConceptAnswers =
    conceptAnswers?.filter(({ id }) => id !== answer.id) || [];

  setAnswers(
    filteredConceptAnswers.length
      ? {
          ...filteredAnswers,
          [answer.questionConceptName]: filteredConceptAnswers
        }
      : filteredAnswers
  );
};

export const mapAnswersToFormSubmission = answers =>
  Object.values(answers).flatMap(conceptAnswers =>
    conceptAnswers.map(
      ({ questionConceptName, answerValue, answerConceptName }) => ({
        questionConceptName,
        answerValue,
        answerConceptName
      })
    )
  );
