import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './PfaQuestionsWrapper.scss';

const { TextArea } = Input;

const PfaQuestionsWrapper = ({
  questionConceptName,
  answers,
  title,
  required,
  ...props
}) => {
  const [checkedAnswersIds, setCheckedAnswersIds] = useState([]);
  const { t } = useTranslation();

  const addToAnswers = answer => {
    props.addToAnswers({ ...answer, questionConceptName });
  };

  const removeFromAnswers = answer => {
    props.removeFromAnswers({ ...answer, questionConceptName });
  };

  const onAnswerChecked = answer => {
    setCheckedAnswersIds([...checkedAnswersIds, answer.id]);
    addToAnswers(answer);
  };

  const onAnswerUnchecked = answer => {
    setCheckedAnswersIds(checkedAnswersIds.filter(id => id !== answer.id));
    removeFromAnswers(answer);
  };

  function onAnswerTextAreaChanged(value, answer) {
    answer.answerValue = value;
    addToAnswers(answer);
  }

  return (
    <div className="pfa-questions-wrapper">
      <p>{t('Pfa Check Boxes')}</p>

      <p>
        {t(title)}
        {required && <span> *</span>}
      </p>

      {answers.map(answer => (
        <div key={answer.id} className="label-wrapper">
          <Checkbox
            id={answer.id}
            defaultChecked={checkedAnswersIds.find(id => id === answer.id)}
            onChange={e =>
              e.target.checked
                ? onAnswerChecked(answer)
                : onAnswerUnchecked(answer)
            }
          >
            {t(answer.description)}
          </Checkbox>
          {answer.textarea && (
            <TextArea
              className="pfa-other-answer-textarea"
              autoSize
              onChange={e => onAnswerTextAreaChanged(e.target.value, answer)}
              value={answer.answerValue}
              placeholder="Other"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default PfaQuestionsWrapper;
