import React from 'react';
import { PatientInfoQuestions } from '../../Shared/FormsQuestions';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

function PresentAtSession({ questionConceptName, ...props }) {
  const { t } = useTranslation();

  const addToAnswers = answer => {
    props.addToAnswers({ ...answer, questionConceptName });
  };

  const removeFromAnswers = answer => {
    props.removeFromAnswers({ ...answer, questionConceptName });
  };

  return (
    <div className="present-session-wrapper">
      <h6 className="present-session-title">
        {t('Also present at session')} <span>(Optional)</span>
      </h6>
      <div className="present-session-checkbox">
        {PatientInfoQuestions.presentAtSession.answers.map((answer, index) => (
          <Checkbox
            key={index}
            value={answer.id}
            onChange={e =>
              e.target.checked
                ? addToAnswers(answer)
                : removeFromAnswers(answer)
            }
          >
            {t(answer.description)}
          </Checkbox>
        ))}
      </div>
    </div>
  );
}

export default PresentAtSession;
