import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

function FormDropdown({ title, questionConceptName, answers, ...props }) {
  const { t } = useTranslation();

  const addToAnswers = answerId => {
    props.addToAnswers({
      ...answers.find(({ id }) => id === answerId),
      questionConceptName
    });
  };

  const removeFromAnswers = answerId => {
    props.removeFromAnswers({
      ...answers.find(({ id }) => id === answerId),
      questionConceptName
    });
  };

  return (
    <div className="form-dropdown-wrapper">
      <h6 className="form-dropdown-title">{`${t(title)} *`}</h6>
      <Select
        suffixIcon={<CaretDownOutlined className="form-dropdown-arrow" />}
        placeholder={`Select ${t(title)}`}
        className="form-dropdown"
        virtual={false}
        onSelect={answerId => addToAnswers(answerId)}
        onDeselect={answerId => removeFromAnswers(answerId)}
      >
        {answers.map((item, index) => (
          <Option key={index} value={item.id}>
            {t(item.description)}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default FormDropdown;
