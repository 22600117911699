import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_ARM } from '../translations/arm/translations';
import { TRANSLATIONS_EN } from '../translations/en/translations';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      arm: {
        translation: TRANSLATIONS_ARM
      }
    }
  });

i18n.changeLanguage('en');
