import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import './FormSuccess.scss';

function FormSuccess() {
  return (
    <div className="submit-success-wrapper">
      <FontAwesomeIcon className="submit-success-icon" icon={faCheckCircle} />
      <p>{`Thank you, the
          information has
          been recorded`}</p>

      <Link className="back-home-btn" to={Paths.chooseForm}>
        Back to Home
      </Link>
    </div>
  );
}

export default FormSuccess;
