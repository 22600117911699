import { FORM_ACTION_TYPES } from '../../types/form';

export const submitForm = formSubmission => ({
  type: FORM_ACTION_TYPES.submit,
  payload: { formSubmission }
});

export const savePatientInfo = patientInfoAnswers => ({
  type: FORM_ACTION_TYPES.savePatientInfo,
  payload: { patientInfoAnswers }
});
