export const TRANSLATIONS_ARM = {
  /*
    >> PATIENT VISIT INFORMATION FORM <<
  */
  'Primary Patient Age': 'Primary Patient Age',
  'Primary Patient Gender': 'Primary Patient Gender',
  'Also Present at Session': 'Also Present at Session',
  Setting: 'Setting',
  Yerevan: 'Yerevan',
  Aragatsotn: 'Aragatsotn',
  Ararat: 'Ararat',
  Armavir: 'Armavir',
  Gegharkunik: 'Gegharkunik',
  Kotayk: 'Kotayk',
  Lori: 'Lori',
  Shirak: 'Shirak',
  Syunik: 'Syunik',
  Tavush: 'Tavush',
  'Vayots Dzor': 'Vayots Dzor',
  '0 - 5 years old': '0 - 5 years old',
  '6 - 10 years old': '6 - 10 years old',
  '11 - 15 years old': '11 - 15 years old',
  '16 - 20 years old': '16 - 20 years old',
  '21 - 30 years old': '21 - 30 years old',
  '31 - 40 years old': '31 - 40 years old',
  '41 - 50 years old': '41 - 50 years old',
  '51 - 60 years old': '51 - 60 years old',
  '61 - 70 years old': '61 - 70 years old',
  '71 - 80 years old': '71 - 80 years old',
  '81+ years old': '81+ years old',
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  'Not Specified': 'Not Specified',
  Location: 'Location',
  Date: 'Date',
  'Also present at session': 'Also present at session',
  Child: 'Child',
  Adolescent: 'Adolescent',
  Adult: 'Adult',
  Family: 'Family',
  Group: 'Group',
  'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?':
    'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?',

  /*
    >> HESPER FORM << 
  */

  /* Answers */
  Yes: 'այո',
  No: 'ոչ',
  'Decline to answer': 'հրաժարվում է պատասխանել',
  'Not available': 'կիրառելի չէ',

  /* HESPER welcome */
  'HESPER form welcome':
    'Հումանիտար արտակարգ իրավիճակներում ընկալվող կարիքների մասշտաբը գնահատելու նպատակով տրվող հարցեր',

  /* Questions */
  // 1
  'Drinking water': 'Խմելու ջուր',
  'Drinking water Question':
    'Դուք ունե՞ք լուրջ խնդիր խմելու կամ խոհարարության համար անհրաժեշտ անվտանգ ջրի ոչ բավարար քանակ ունենալու պատճառով:',
  //2
  Food: 'Սնունդ',
  'Food Question':
    'Դուք ունե՞ք սննդի հետ կապված լուրջ խնդիրներ? Օրինակ, ոչ բավարար քանակությամբ կամ անորակ սնունդ ունենալու պատճառով կամ որովհետև դուք չեք կարող պատրաստել:',
  // 3
  'Place to live in': 'Ապրելու տեղ',
  'Place to live in Question':
    'Դուք ունե՞ք լուրջ խնդիր ապրելու հարմար տեղ չունենալու պատճառով?',
  // 4
  Toilets: 'Զուգարան',
  'Toilets Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ չունեք հեշտ և անվտանգ հասանելիություն մաքուր զուգարանի?',
  // 5
  'Keeping clean': 'Մաքրության ապահովում',
  'Keeping clean Question': `ա. Տղամարդկանց դեպքում. Դուք ունե՞ք լուրջ խնդիր, քանի որ ձեր իրավիճակում դժվար է պահպանել մաքրություն? Օրինակ, բավարար օճառ, ջուր կամ լվացվելու համար հարմարավետ տեղ չունենալու պատճառով:
  բ. Կանանց դեպքում. Դուք ունե՞ք լուրջ խնդիր, քանի որ ձեր իրավիճակում դժվար է պահպանել մաքրություն? Օրինակ, բավարար օճառ, հիգիենայի պարագաներ, ջուր կամ լվացվելու համար հարմարավետ տեղ չունենալու պատճառով:`,
  // 6
  'Clothes, shoes, bedding or blankets':
    'Հագուստ, կոշիկներ, անկողնու պարագաներ կամ վերմակներ',
  'Clothes, shoes, bedding or blankets Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ չունեք բավարար կամ պիտանի հագուստ, կոշիկներ, անկողնու պատրագաներ կամ վերմակներ:',
  // 7
  'Income or livelihood': 'Եկամուտ կամ ապրուստի միջոց',
  'Income or livelihood Question':
    'Դուք ունե՞ք լուրջ խնդիր բավարար եկամուտ, փող կամ ապրելու միջոցներ չունենալու պատճառով:',
  // 8
  'Physical health': 'Ֆիզիկական առողջություն',
  'Physical health Question':
    'Դուք ունե՞ք ֆիզիկական առողջության հետ կապված լուրջ խնդիր? Օրինակ, ֆիզիկական հիվանդություն, վնասվածք կամ հաշմանդամություն:',
  // 9
  'Health care': 'Առողջապահություն',
  'Health care Question': `ա. Տղամարդկանց դեպքում. Դուք ունե՞ք լուրջ խնդիր որակյալ առողջապահությանը հասանելիություն չունենալու պատճառով: Օրինակ, բուժման մեթոդների կամ դեղորայքին հասանելիություն:   
    բ. Կանանց դեպքում. Դուք ունե՞ք լուրջ խնդիր որակյալ առողջապահությանը հասանելիություն չունենալու պատճառով: Օրինակ, բուժմանը կամ դեղորայքին հասանելիություն կամ հղիության կամ ծննդաբերության ընթացքում առողջապահությանը հասանելիություն:`,
  // 10
  Distress: 'Նեղություն',
  'Distress Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ շատ նեղված եք: Օրինակ, շատ վրդովված եք, տխուր եք, անհանգստացած եք, վախեցած կամ զայրացած եք:',
  // 11
  Safety: 'Անվտանգություն',
  'Safety Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ դուք կամ ձեր ընտանիքը ապահով կամ պաշտպանված չեք ձեր ներկայիս բնակության վայրում: Օրինակ, ձեր համայնքում, քաղաքում կամ գյուղում բախումների,  բռնությունների կամ հանցագործությունների պատճառով:',
  // 12
  'Education for your children': 'Երեխաների կրթություն',
  'Education for your children Question':
    'Դու՞ք ունեք լուրջ խնդիր, քանի որ ձեր երեխաները չեն հաճախում դպրոց կամ չեն ստանում որակյալ կրթություն:',
  // 13
  'Care for family members': 'Ընտանիքի անդամների խնամք',
  'Care for family members Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ ձեր իրավիճակում դժվար է խնամել ձեզ հետ բնակվող ընտանիքի անդամներին: Օրինակ, ընտանիքի երիտասարդ երեխաներին կամ ֆիզիկական, մտավոր հիվանդություններ կամ հաշմանդամություն ունեցող տարեց անդամներին:',
  // 14
  'Support from others': 'Աջակցություն հասարակությունից',
  'Support from others Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ չեք ստանում բավարար աջակցություն ձեր համայնքի անդամներից: Օրինակ, հոգեբանական աջակցություն կամ գործնական օգնություն:',
  // 15
  'Separation from family members': 'Ընտանիքի անդամներից բաժանում',
  'Separation from family members Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ բաժանված եք ընտանիքի անդամներից:',
  // 16
  'Being displaced from home': 'Տանից տեղահանված լինելու հանգամանք',
  'Being displaced from home Question':
    'Դուք ունե՞ք լուրջ խնդիր հայրենի երկրից, քաղաքից կամ գյուղից տեղահանված լինելու պատճառով:',
  // 17
  Information: 'Տեղեկատվություն',
  'Information Question': `Տեղահանված մարդկանց դեպքում. Դուք ունե՞ք լուրջ խնդիր ոչ բավարար տեղեկատվություն ունենալու պատճառով: Օրինակ, հասանելի օգնության վերաբերյալ կամ ձեր երկրում կամ բնակավայրում կատարվող իրադարձությունների մասին ոչ բավարար տեղեկատվություն ունենալու պատճառով: 
    Ոչ տեղահանված մարդկանց դեպքում. Դուք ունե՞ք լուրջ խնդիր ոչ բավարար տեղեկատվություն ունենալու պատճառով? Օրինակ, հասանելի օգնության վերաբերյալ ոչ բավարար տեղեկատվություն ունենալու պատճառով:`,
  // 18
  'The way aid is provided': 'Օգնության տրամադրման եղանակ',
  'The way aid is provided Question':
    'Դուք ունե՞ք լուրջ խնդիր ոչ համարժեք օգնություն ստանալու պատճառով: Օրինակ, առկա օգնությանը  արդարացի հասանելիոթյուն չունենալու պատճառով կամ գործակալությունների կողմից ձեր համայնքի անդամներին չներգրավելու պատճառով:',
  // 19
  Respect: 'Հարգանք',
  'Respect Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ ձեզ հարգված չեք զգում կամ ձեզ նվաստացված եք զգում: Օրինակ, ձեր ապրելակերպի պատճառով կամ ձեր նկատմամբ մարդկանց վերաբերմունքի պատճառով:',
  // 20
  'Moving between places': 'Վայրից վայր տեղաշարժ',
  'Moving between places Question':
    'Դուք ունե՞ք լուրջ խնդիր, քանի որ չունեք հնարավորություն տեղաշարժվել վայրից վայր: Օրինակ, այլ գյուղ կամ քաղաք գնալու անհնարինության պատճառով:',
  // 21
  'Law and justice in your community':
    'Ձեր համայնքում օրենքի ուժն ու արդարադատությունը',
  'Law and justice in your community Question':
    'Ձեր համայնքում առկա՞ է լուրջ խնդիր օրենքի կամ արդարադատության ոչ համարժեք համակարգի պատճառով կամ այն պատճառով, որ մարդիկ չունեն բավարար գիտելիքներ իրենց իրավունքների մասին:',
  // 22
  'Safety or protection from violence for women in your community':
    'Ձեր համայնքում կանանց անվտանգություն կամ պաշտպանություն բռնությունից',
  'Safety or protection from violence for women in your community Question':
    'Ձեր համայնքում կամ իրենց ընտանիքներում կանայք ունե՞ն լուրջ խնդիրներ իրենց նկատմամբ ֆիզիկական կամ սեռական բռնության ենթարկվելու պատճառով:',
  // 23
  'Alcohol or drug use in your community':
    'Ձեր համայնքում ալկոհոլի կամ թմրանյութերի օգտագործում',
  'Alcohol or drug use in your community Question':
    'Ձեր համայնքում առկա՞ է լուրջ խնդիր կապված մեծ քանակությամբ ալկոհոլ կամ վնասակար թմրանյութեր օգտագործելու հետ:',
  // 24
  'Mental illness in your community': 'Ձեր համայնքում հոգեկան հիվանդություններ',
  'Mental illness in your community Question':
    'Ձեր համայնքում առկա՞ է լուրջ խնդիր կապված հոգեկան հիվանդություն ունեցող մարդկանց հետ:',
  // 25
  'Care for people in your community who are on their own':
    'Ձեր համայնքում միայնակ մարդկանց խնամք',
  'Care for people in your community who are on their own Question':
    'Ձեր համայնքում առկա՞ է լուրջ խնդիր, քանի որ միայնակ մարդիկ ունեն չեն ստանում բավարար խնամք: Օրինակ, անուղեկցորդ երեխաների, այրիների կամ մեծահասակների, ֆիզիկական կամ հոգեկան հիվանդություններ կամ հաշմանդամություն ունեցող անուղեկցորդ մարդկանց համար ոչ բավարար խնամքի առկայության պատճառով:',
  // 26
  'COVID-19': 'ԿՈՎԻԴ -19',
  'COVID-19 Question':
    'Արդյո՞ք առկա է լուրջ խնդիր կապված դիմակների, ձեռքերի ախտահանիչ միջոցների, օճառի մատակարարման հետ կամ ջրին հասանելիություն չունենալու պատճառով ԿՈՎԻԴ-ի տարածումը ձեր համայնքում կանխելու նպատակով:',
  // 27
  'COVID-19 social distance': 'ԿՈՎԻԴ-19',
  'COVID-19 social distance Question':
    'Արդյո՞ք առկա է լուրջ խնդիր ձեր անմիջական ընտանիքից դուրս մարդկանց հետ սոցիալական հեռավորություն պահպանելու անհնարինության հետ կապված:',
  // 28
  Technology: 'Տեխնոլոգիա',
  'Technology Question':
    'Դուք ունե՞ք հասանելիություն բջջային հեռախոսին, սարքը լիցքավորելու համար անհրաժեշտ էլեկտրականության և հուսալի ինտերնետի:',
  // 29
  'Technology cost': 'Տեխնոլոգիա',
  'Technology cost Question':
    'Դուք կարո՞ղ եք ձեզ թույլ տալ բջջային հեռախոսի արժեքը:',

  /*
    >> PFA FORM <<
  */

  /* PFA welcome */
  'PFA form welcome': `Տեղում հոգեբանական առաջին օգնություն ցուցաբերելու նպատակով տրվող հարցեր`,

  'Pfa Check Boxes':
    'Նշեք արտակարգ իրավիճակը վերապրած անձի իրավիճակին համապատասխանող պատասխանը',

  /* Behaviour */
  Behaviour: 'Վարքագիծ',
  'Extreme disorientation': 'Ծայրահեղ ապակողմնորոշում',
  'Excessive drug, alcohol, or prescription drug use':
    'Թմրամիջոցների, ալկոհոլի կամ դեղատոմսով դուրսգրվող դեղերի չափից ավել օգտագործում',
  'Isolation/ withdrawal': 'Մեկուսացում / հեռացում',
  'High risk behavior': 'Բարձր ռիսկային վարքագիծ',
  'Regressive behavior': 'Հետընթաց վարք',
  'Separation anxiety': 'Բաժանվելուց վախ',
  'Violent behavior': 'Ագրեսիվ պահվածք',
  'Maladaptive coping': 'Ոչ ադապտիվ քոփինգ',
  'None of the above': 'Ոչ մեկը',
  'Other (please specify)': 'Այլ',

  /* Emotional */
  Emotional: 'Էմոցիոնալ հատկանիշներ',
  'Acute stress reactions': 'Սուր սթրեսային ռեակցիաներ',
  'Acute grief reactions': 'Վշտի սուր արձագանքներ',
  'Sadness, tearfulness': 'Տխրություն, արցունքոտություն',
  'Irritability, anger': 'Դյուրագրգռություն, զայրույթ',
  'Feeling anxious, fearful': 'Անհանգստության, վախի զգացում',
  'Despair, hopelessness': 'Հուսահատություն, անելանելիություն',
  'Feelings of guilt or shame': 'Մեղքի կամ ամոթի զգացողություններ',
  'Feeling emotionally numb, disconnected':
    'Հուզական թմրության, անջատվածության զգացում',

  /* Difficulties Survivor */
  'Difficulties Survivor':
    'Նշեք արտակարգ իրավիճակը վերապրած անձի իրավիճակի համար `բնորոշ դժվարությունների համար:',
  'Past or preexisting trauma/psychological problems/substance abuse problems':
    'Անցյալում ստացված կամ գոյություն ունեցող տրավմայի/հոգեբանական խնդիրների/հոգեներգործուն նյութերի չարաշահման հետ կապված խնդիրներ',
  'Injured as a result of the war': 'Պատերազմում վիրավորում ստացած',
  'At risk of losing life during the war':
    'Պատերազմում կյանքը կորցնելու ռիսկի խմբում',
  'Loved one(s) missing or dead': 'Անհետ կորած կամ մահացած հարազատ/-ներ',
  'Ill/injured as a result of COVID-19 pandemic':
    'Կովիդ-19 համաճարակի հետևանքով առողջությանը վնաս ստացած կամ վարակված անձ',
  'Ill/injured/lost loved one as a result of COVID-19 pandemic':
    'Կովիդ-19 համաճարակի հետևանքով առողջությանը վնաս ստացած, վարակված կամ մահացած սիրած էակ',
  'Financial concerns': 'Ֆինանսական խնդիրներ',
  'Displaced from home': 'Բնակավայրից տեղահանված անձ',
  'Living arrangements': 'Բնակարանային պայմաններ',
  'Lost job or school': 'Աշխատանքի կամ դպրոցի կորուստ',
  'Assisted with rescue/recovery':
    'Փրկության/վերականգման աջակցություն ստացած անձ',
  'Has physical/emotional disability':
    'Անձն ունի ֆիզիկական/էմոցիոնալ անզորություն',
  'Medication stabilization': 'Դեղորայքի միջոցով կայունացում',
  'Concerns about child/adolescent': 'Երեխայի/դեռահասի մասին մտահոգություններ',
  'Spiritual concerns': 'Հոգևոր մտահոգություններ',

  /* Referral */
  Referral: 'Ուղղորդում',
  'Substance abuse treatment': 'Հոգեներգործուն նյութերից կախվածության բուժում',
  'Other disaster agencies': 'Արտակարգ իրավիճակների այլ գործակալություններ',
  'Other community services': 'Այլ հանրային ծառայություններ',
  'Professional mental health services':
    'Հոգեկան առողջության մասնագիտական ծառայություններ',
  Clergy: 'Հոգևորականներ',
  'Medical treatment': 'Բուժում',
  'Did not make referral': 'Ուղղորդում չի առաջարկվել',
  'Within project (please specify)': 'Նախագծի շրջանակներում (նշել)',

  /* Please Make Note */
  'Please make note':
    'Խնդրում ենք հաշվի առնել ցանկացած տեղեկատվություն, որը կարող է օգտակար լինել ուղղորդման հարցում:',

  /* Accepted By Individual  */
  'Referral Accepted By Individual': 'Ուղղորդումը ընդունվե՞լ է անձի կողմից:'
};
