import React from 'react';
import { PatientInfoQuestions } from '../../Shared/FormsQuestions';
import { Input } from 'antd';
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

function Setting({ addToAnswers }) {
  const { t } = useTranslation();

  function onTextChange(value) {
    addToAnswers({
      id: PatientInfoQuestions.setting.questionConceptName,
      questionConceptName: PatientInfoQuestions.setting.questionConceptName,
      answerValue: value
    });
  }
  return (
    <div className="setting-wrapper">
      <h6 className="setting-title">
        {t('Setting')}<span>(Optional)</span>
      </h6>
      <TextArea
        placeholder="Describe the setting here"
        maxLength={280}
        showCount
        autoSize
        onChange={e => onTextChange(e.target.value)}
      />
    </div>
  );
}

export default Setting;
