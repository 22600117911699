import React from 'react';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import './FormHead.scss';

function FormHead(props) {
  const { formTitle, progress = 0, isFormDescription } = props;
  return (
    <div className="form-head-wrapper">
      <div className="form-head">
        <Link
          to={isFormDescription ? Paths.patientInfo : Paths.FormDescription}
        >
          <LeftCircleOutlined className="form-head-back-btn" />
        </Link>
        {!isFormDescription && <h6 className="form-head-title">{formTitle}</h6>}
        <div />
      </div>
      {!isFormDescription && (
        <div className="form-progress-wrapper">
          <Progress
            className="form-progress"
            showInfo={false}
            percent={progress}
          />
        </div>
      )}
    </div>
  );
}

export default FormHead;
