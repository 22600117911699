import React from 'react';
import { useTranslation } from 'react-i18next';
import { PfaQuestions } from '../../Shared/FormsQuestions';
import { Input } from 'antd';
import './PfaQuestionsWrapper.scss';

const { TextArea } = Input;

function ReferralOtherInfo({ addToAnswers }) {
  const onTextChange = value => {
    addToAnswers({
      id: PfaQuestions.referralOtherInfo.questionConceptName,
      questionConceptName: PfaQuestions.referralOtherInfo.questionConceptName,
      answerValue: value
    });
  };

  const { t } = useTranslation();

  return (
    <div className="pfa-note-wrapper">
      <p>{t(`Please make note`)}</p>
      <TextArea
        className="pfa-note-answer-textarea"
        autoSize
        onChange={e => onTextChange(e.target.value)}
        placeholder="Additional information"
      />
    </div>
  );
}

export default ReferralOtherInfo;
