import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import HeadPhoneIcon from '../../Assets/Images/HeadPhone_icon.svg';
import './ContactUsModal.scss';

function ContactUsModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState('');

  const { TextArea } = Input;

  const handleChange = e => {
    setValue(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const mailInfo = `mailto:help@learnwithopen.org?subject=Avetis 2.0 Mental Health Feedback&body=${value}`;

  return (
    <>
      <div className="contact-us-modal-open" onClick={showModal}>
        <img src={HeadPhoneIcon} alt="contact_us_icon" />
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        title={null}
        wrapClassName="contact-us-modal"
        width="307px"
      >
        <h1>contact us</h1>
        <p>
          {`We appreciate any feedback or
            questions.`}
        </p>
        <p>
          {`If you would like a response, please
            provide an email address to allow us to
            follow up.`}
        </p>
        <TextArea
          className="contact-us-text-area"
          onChange={handleChange}
          placeholder="Message"
          value={value}
        />
        <div className="contact-us-send-btn-wrapper">
          <a
            className={`contact-us-send-btn ${value === '' && 'disabled'}`}
            disabled={value === ''}
            onClick={handleCancel}
            href={mailInfo}
          >
            send
          </a>
        </div>
      </Modal>
    </>
  );
}

export default ContactUsModal;
