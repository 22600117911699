import axios from 'axios';
import qs from 'qs';

export const apiEndpoints = {
  local: 'http://localhost:3000/local/',
  staging: 'https://8lgmdbjeai.execute-api.us-west-2.amazonaws.com/staging/',
  production:
    'https://u3qb0nm07g.execute-api.us-west-2.amazonaws.com/production/'
};

export const axiosInstance = axios.create({
  baseURL: apiEndpoints[process.env.REACT_APP_STAGE],
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
    //TODO: Add Authorization with the token
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
});
