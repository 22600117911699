export const TRANSLATIONS_EN = {
  /*
    >> PATIENT VISIT INFORMATION FORM <<
  */
  'Primary Patient Age': 'Primary Patient Age',
  'Primary Patient Gender': 'Primary Patient Gender',
  'Also Present at Session': 'Also Present at Session',
  Setting: 'Setting',
  Yerevan: 'Yerevan',
  Aragatsotn: 'Aragatsotn',
  Ararat: 'Ararat',
  vir: 'vir',
  Gegharkunik: 'Gegharkunik',
  Kotayk: 'Kotayk',
  Lori: 'Lori',
  Shirak: 'Shirak',
  Syunik: 'Syunik',
  Tavush: 'Tavush',
  'Vayots Dzor': 'Vayots Dzor',
  '0 - 5 years old': '0 - 5 years old',
  '6 - 10 years old': '6 - 10 years old',
  '11 - 15 years old': '11 - 15 years old',
  '16 - 20 years old': '16 - 20 years old',
  '21 - 30 years old': '21 - 30 years old',
  '31 - 40 years old': '31 - 40 years old',
  '41 - 50 years old': '41 - 50 years old',
  '51 - 60 years old': '51 - 60 years old',
  '61 - 70 years old': '61 - 70 years old',
  '71 - 80 years old': '71 - 80 years old',
  '81+ years old': '81+ years old',
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  'Not Specified': 'Not Specified',
  Location: 'Location',
  Date: 'Date',
  'Also present at session': 'Also present at session',
  Child: 'Child',
  Adolescent: 'Adolescent',
  Adult: 'Adult',
  Family: 'Family',
  Group: 'Group',
  'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?':
    'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?',

  /*
    >> HESPER FORM << 
  */

  /* Answers */
  Yes: 'Yes',
  No: 'No',
  'Decline to answer': 'Decline to answer',
  'Not available': 'Not available',

  /* HESPER welcome */
  'HESPER form welcome': `The Humanitarian Emergency Settings
          Perceived Needs Scale (HESPER) provides a
          quick way of assessing the needs of people
          affected by humanitarian emergencies,
          such as war or major natural disaster`,

  /* Questions */
  // 1
  'Drinking water': 'Drinking water',
  'Drinking water Question':
    'Do you have a serious problem because you do not have enough water that is safe for drinking or cooking?',
  // 2
  Food: 'Food',
  'Food Question':
    'Do you have a serious problem with food? For example, because you do not have enough food, or good enough food, or because you are not able to cook food.',
  // 3
  'Place to live in': 'Place to live in',
  'Place to live in Question':
    'Do you have a serious problem because you do not have a suitable place to live in?',
  // 4
  Toilets: 'Toilets',
  'Toilets Question':
    'Do you have a serious problem because you do not have easy and safe access to a clean toilet?',
  // 5
  'Keeping clean': 'Keeping clean',
  'Keeping clean Question':
    'For men: Do you have a serious problem because in your situation it is difficult to keep clean? For example, because you do not have enough soap, water or a suitable place to wash.\n For women: Do you have a serious problem because in your situation it is difficult to keep clean? For example, because you do not have enough soap, sanitary materials, water or a suitable place to wash.',
  // 6
  'Clothes, shoes, bedding or blankets': 'Clothes, shoes, bedding or blankets',
  'Clothes, shoes, bedding or blankets Question':
    'Do you have a serious problem because you do not have enough, or good enough, clothes, shoes, bedding or blankets?',
  // 7
  'Income or livelihood': 'Income or livelihood',
  'Income or livelihood Question':
    'Do you have a serious problem because you do not have enough income, money or resources to live?',
  // 8
  'Physical health': 'Physical health',
  'Physical health Question':
    'Do you have a serious problem with your physical health? For example, because you have a physical illness, injury or disability.',
  // 9
  'Health care': 'Health care',
  'Health care Question':
    'For men: Do you have a serious problem because you are not able to get adequate health care for yourself? For example, treatment or medicines.\n For women: Do you have a serious problem because you are not able to get adequate health care for yourself? For example, treatment or medicines, or health care during pregnancy or childbirth.',
  // 10
  Distress: 'Distress',
  'Distress Question':
    'Do you have a serious problem because you feel very distressed? For example, very upset, sad, worried, scared, or angry.',
  // 11
  Safety: 'Safety',
  'Safety Question':
    'Do you have a serious problem because you or your family are not safe or protected where you live now? For example, because of conflict, violence or crime in your community, city or village.',
  // 12
  'Education for your children': 'Education for your children',
  'Education for your children Question':
    'Do you have a serious problem because your children are not in school or are not getting a good enough education?',
  // 13
  'Care for family members': 'Care for family members',
  'Care for family members Question':
    'Do you have a serious problem because in your situation it is difficult to care for family members who live with you? For example, young children in your family, or family members who are elderly, physically or mentally ill, or disabled.',
  // 14
  'Support from others': 'Support from others',
  'Support from others Question':
    'Do you have a serious problem because you are not getting enough support from people in your community? For example, emotional support or practical help.',
  // 15
  'Separation from family members': 'Separation from family members',
  'Separation from family members Question':
    'Do you have a serious problem because you are separated from family members?',
  // 16
  'Being displaced from home': 'Being displaced from home',
  'Being displaced from home Question':
    'Do you have a serious problem because you have been displaced from your home country, city or village?',
  // 17
  Information: 'Information',
  'Information Question':
    'For displaced people: Do you have a serious problem because you do not have enough information? For example, because you do not have enough information about the aid that is available or because you do not have enough information about what is happening in your home country or hometown.\n For non-displaced people: Do you have a serious problem because you do not have enough information? For example, because you do not have enough information about the aid that is available.',
  // 18
  'The way aid is provided': 'The way aid is provided',
  'The way aid is provided Question':
    'Do you have a serious problem because of inadequate aid? For example, because you do not have fair access to the aid that is available, or because aid agencies are working on their own without involvement from people in your community.',
  // 19
  Respect: 'Respect',
  'Respect Question':
    'Do you have a serious problem because you do not feel respected or you feel humiliated? For example, because of the situation you are living in, or because of the way people treat you.',
  // 20
  'Moving between places': 'Moving between places',
  'Moving between places Question':
    'Do you have a serious problem because you are not able to move between places? For example, going to another village or town.',
  // 21
  'Law and justice in your community': 'Law and justice in your community',
  'Law and justice in your community Question':
    'Is there a serious problem in your community because of an inadequate system for law and justice, or because people do not know enough about their legal rights?',
  // 22
  'Safety or protection from violence for women in your community':
    'Safety or protection from violence for women in your community',
  'Safety or protection from violence for women in your community Question':
    'Is there a serious problem for women in your community because of physical or sexual violence towards them, either in the community or in their homes?',
  // 23
  'Alcohol or drug use in your community':
    'Alcohol or drug use in your community',
  'Alcohol or drug use in your community Question':
    'Is there a serious problem in your community because people drink a lot of alcohol, or use hul drugs?',
  // 24
  'Mental illness in your community': 'Mental illness in your community',
  'Mental illness in your community Question':
    'Is there a serious problem in your community because people have a mental illness?',
  // 25
  'Care for people in your community who are on their own':
    'Care for people in your community who are on their own',
  'Care for people in your community who are on their own Question':
    'Is there a serious problem in your community because there is not enough care for people who are on their own? For example, care for unaccompanied children, widows or elderly people, or unaccompanied people who have a physical or mental illness, or disability.',
  // 26
  'COVID-19': 'COVID-19',
  'COVID-19 Question':
    'Is there a serious problem accessing supplies like face masks, hand sanitizer, soap, and water to be able to prevent the spread of COVID in your community?',
  // 27
  'COVID-19 social distance': 'COVID-19',
  'COVID-19 social distance Question':
    'Is there a serious problem not being able to maintain social distance from people outside of your immediate family?',
  // 28
  Technology: 'Technology',
  'Technology Question':
    'Do you have access to a cell phone, the power necessary to charge the device and reliable wireless access?',
  'Technology a Question': 'Are you able to afford the cost of a cell phone?',
  // 29
  'Technology cost': 'Technology',
  'Technology cost Question':
    'Are you able to afford the cost of a cell phone?',

  /*
    >> PFA FORM <<
  */

  /* PFA welcome */
  'PFA form welcome': `The Psychological First Aid (PFA) Field
          Operations provides an assessment of
          needs to give guidance on using the
          Psychological First Aid intervention.`,

  'Pfa Check Boxes':
    'Check the boxes corresponding to difficulties the survivor is experiencing.',

  /* Behaviour */
  Behaviour: 'Behaviour',
  'Extreme disorientation': 'Extreme disorientation',
  'Excessive drug, alcohol, or prescription drug use':
    'Excessive drug, alcohol, or prescription drug use',
  'Isolation/ withdrawal': 'Isolation/ withdrawal',
  'High risk behavior': 'High risk behavior',
  'Regressive behavior': 'Regressive behavior',
  'Separation anxiety': 'Separation anxiety',
  'Violent behavior': 'Violent behavior',
  'Maladaptive coping': 'Maladaptive coping',
  'None of the above': 'None of the above',
  'Other (please specify)': 'Other (please specify)',

  /* Emotional */
  Emotional: 'Emotional',
  'Acute stress reactions': 'Acute stress reactions',
  'Acute grief reactions': 'Acute grief reactions',
  'Sadness, tearfulness': 'Sadness, tearfulness',
  'Irritability, anger': 'Irritability, anger',
  'Feeling anxious, fearful': 'Feeling anxious, fearful',
  'Despair, hopelessness': 'Despair, hopelessness',
  'Feelings of guilt or shame': 'Feelings of guilt or shame',
  'Feeling emotionally numb, disconnected':
    'Feeling emotionally numb, disconnected',

  /* Difficulties Survivor */
  'Difficulties Survivor': 'Difficulties Survivor',
  'Referral Accepted By Individual': 'Referral Accepted By Individual',
  'Past or preexisting trauma/psychological problems/substance abuse problems':
    'Past or preexisting trauma/psychological problems/substance abuse problems',
  'Injured as a result of the war': 'Injured as a result of the war',
  'At risk of losing life during the war':
    'At risk of losing life during the war',
  'Loved one(s) missing or dead': 'Loved one(s) missing or dead',
  'Ill/injured as a result of COVID-19 pandemic':
    'Ill/injured as a result of COVID-19 pandemic',
  'Ill/injured/lost loved one as a result of COVID-19 pandemic':
    'Ill/injured/lost loved one as a result of COVID-19 pandemic',
  'Financial concerns': 'Financial concerns',
  'Displaced from home': 'Displaced from home',
  'Living arrangements': 'Living arrangements',
  'Lost job or school': 'Lost job or school',
  'Assisted with rescue/recovery': 'Assisted with rescue/recovery',
  'Has physical/emotional disability': 'Has physical/emotional disability',
  'Medication stabilization': 'Medication stabilization',
  'Concerns about child/adolescent': 'Concerns about child/adolescent',
  'Spiritual concerns': 'Spiritual concerns',

  /* Referral */
  Referral: 'Referral',
  'Substance abuse treatment': 'Substance abuse treatment',
  'Other disaster agencies': 'Other disaster agencies',
  'Other community services': 'Other community services',
  'Professional mental health services': 'Professional mental health services',
  Clergy: 'Clergy',
  'Medical treatment': 'Medical treatment',
  'Did not make referral': 'Did not make referral',
  'Within project (please specify)': 'Within project (please specify)',

  /* Please Make Note */
  'Please make note':
    'Please make note of any other information that might be helpful in making a referral.',

  /* Accepted By Individual  */
  'Referral Accepted By Individual': 'Referral Accepted By Individual'
};
