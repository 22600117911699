import { call, put, takeLatest } from 'redux-saga/effects';
import { FORM_ACTION_TYPES } from '../../types/form';
import API from '../../../Network/APIs/form-submission';
import { savePatientInfo } from '../../actions/form';

export function* submitForm({ payload }) {
  try {
    yield call(API.submitForm, payload.formSubmission);
    yield put(savePatientInfo([]));
  } catch (e) {
    console.log(e);
  }
}

export function* formSaga() {
  yield takeLatest(FORM_ACTION_TYPES.submit, submitForm);
}
