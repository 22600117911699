import { FORM_ACTION_TYPES } from '../../types/form';

const INITIAL_STATE = {
  patientInfoAnswers: []
};

const form = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.savePatientInfo:
      return {
        ...state,
        patientInfoAnswers: action.payload.patientInfoAnswers
      };
    default:
      return state;
  }
};

export default form;
